<template>
    <div class="event-card-component column" v-if="component" @click="updateCurrentComponent"
    :class="{'component-active': isActive}">
        <div class="legend centered"> {{ date || $t('dateTag')}} - {{ title || $t('eventTitleTag')}} </div>
        <div class="row">
            <div class="column">
                <div class="row">
                    <label for="text">{{$t('dateTag')}}:</label>
                    <input type="text" v-model="date" :placeholder="$t('dateTag')+'...'">
                </div>
                <div class="row">
                    <label for="text">{{$t('eventTitleTag')}}:</label>
                    <input type="text" v-model="title" :placeholder="$t('titlePlaceHolder')">
                </div>
            </div>
            <div class="column">
                <image-upload  :component="component" :options="imageUploadOptions"/>
            </div>
        </div>
        <div class="row">
            <label for="text">{{$t('descriptionTag')}}:</label>
                <ckeditor :editor="editor" :placeholder="$t('descriptionTag')+'...'"
                    v-model="description" :config="editorConfig"></ckeditor>
        </div>
        <button class="btn secondary delete" @click="removeTimeLineEvent">{{$t('delete')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'EventCard', 
    mixins:[BackOfficeComponentMixin], 
    computed:{
        date:{
            get(){
                return this.component.date[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'date', this.locale)
            }
        },
        title:{
            get(){
                return this.component.title[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'title', this.locale)
            }
        },
        description:{
            get(){
                return this.component.longDescription[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'longDescription',this.locale)
            }
        },
        imageUploadOptions(){
            return {name: 'image-upload', text:'addImageText', model:'imageId'}
        },
        isActive(){
            return this.component == this.$store.state.currentBackOfficeComponent
        }
    }, 
    methods:{
        removeTimeLineEvent(){
            this.$store.commit('updateLoadingStatus', true)
            setTimeout(()=>{
                this.$store.dispatch('removeTimeLineEvent', this.component)
                    .then(()=>{
                        this.$store.commit('updateCurrentBackOfficeComponent', undefined)
                    })
                this.$store.commit('updateLoadingStatus', false)
            }, 500)
        }
    }
}
</script>
<style lang="less">

.event-card-component{
    position: relative;
    cursor: pointer;
    margin-top: 16px;
    width: 704px;
    margin-left: 8px;
    border-radius: 4px;
    background-color: #F6F7FA;
    box-shadow: var(--primary-shadow);
    padding: 16px;
    box-sizing: border-box;

    .legend{
        width: 470px;
        max-height: 72px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &.component-active{
        background-color: #fff;
        box-shadow: var(--secondary-shadow);
    }

    :global(.image-upload){
        margin-top: 59px;
        margin-left: 13px;
        height: 88px;
        background-color: #EFEFEF;
    }
    input{
        max-width: 276px;
        width: 276px;
        margin-left: 13px;
        box-sizing: border-box;
    }
    
    input{
        height: 40px;
    }
    .column .row{
        margin-top: 8px;
        position: relative;
    }
    
    > .row{
        label{
            width: 87px;
            max-width: 87px;
            align-self: center;
        }
    }
    .row:first-child{
        margin-top: 59px;
    }
    .legend{
        top: 36px;
    }
    >.btn{
        position: absolute;
        top:16px;
        right: 16px;
    }

    .ck.ck-editor{
        margin-left: 16px;
        margin-top: 8px;
        width: 550px;
        min-height: 120px;
        .ck-content{
            min-height: 120px;
        }
    }
}

</style>



